/* Overlay: Covers the entire screen with black transparency */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    backdrop-filter: blur(2px);
    z-index: 999; /* Ensure it's below the sheet but above content */
    transition: opacity 0.3s ease-in-out;
}

.loginBottomSheet {
    position: fixed;
    /* right: 0;
    top: 0;
    height: 100%;
    width: 400px; */
    /* Default width for larger screens */
    max-width: 100%;
    background-color: #fff;
    box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
    .loginBottomSheet {
        bottom: 0;
        /* Position at bottom */
        width: 100%;
        /* Cover full width */
        border-radius: 12px 12px 0 0;
        /* Rounded corners at the top */
        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
        transform: translateY(100%);
        /* Start hidden */
    }
}

/* For screens smaller than 768px */
@media (min-width: 768px) {
    .loginBottomSheet {
        right: 0;
        top: 0;
        height: 100%;
        width: 400px;
        /* Default width for larger screens */
        max-width: 100%;
    }
}


.loginBottomSheet.open {
    transform: translateX(0);
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    background: #ffffff;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.closeButton {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.closeButton:hover {
    background-color: #f1f1f1;
}

.closeIcon {
    width: 24px;
    height: 24px;
    stroke: #333;
    transition: stroke 0.3s ease;
}

.closeButton:hover .closeIcon {
    stroke: #000;
}

/* .content {
    padding: 32px;
    overflow-y: auto;
    height: calc(100% - 56px);
} */

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 88%;
    padding: 32px;
}

.bottomLink {
    margin-top: auto;
    text-align: center;
    padding: 16px 0;
}

.bottomLink a {
    color: #007bff;
    text-decoration: none;
}

.bottomLink a:hover {
    text-decoration: underline;
}

.inputContainer {
    display: flex;
    /* align-items: center; */
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
}

.countryCodeSelect {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    height: 42px;
}

.countryCodeSelected {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    height: 42px;
    width: 120px;
}

.countryFlag {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.countryCode {
    margin: 0;
    font-weight: bold;
}

.arrowIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    fill: #333;
}

.phoneNumberInput {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 4px 4px 0;
    outline: none;
    height: 42px;
}

.phoneNumberInput:focus {
    border-color: #5d00ff;
}

.phoneNumberInput.error {
    border-color: red;
}

.selectWrapper {
    position: relative;
    width: 100%;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #ffffff;
    border: 1px solid #dedede;
    border-radius: 8px;
    box-shadow: 0px 16px 32px 0px #00000010;
    margin-top: 10px;
    max-height: 276px;
    overflow-y: auto;
    z-index: 1000;
    opacity: 1;
    transform: none;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1),
        transform 133ms cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdownMenu ul {
    padding: 8px 0;
    list-style: none;
    margin: 0;
}

.dropdownMenu li {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid #fafafa;
}

.dropdownMenu li:hover {
    background-color: #ede3ff;
}

.dropdownMenu img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.dropdownMenu span {
    font-weight: bold;
    color: #10002b;
}

.errorMessage {
    color: red;
    margin-top: 8px;
    font-size: 14px;
    height: 18px;
}

.phoneNumberInputContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.sendOTPButton {
    background: #4a0db3;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 1.143em;
    font-weight: 600;
    height: 50px;
    width: 100%;
}

.sendOTPButton:hover {
    background: #1c004c;
}

.sendOTPButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.loginTerms {
    font-size: 14px;
}

.loginTerms>p {
    color: #696d7b;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 10px;
}

.loginTerms a {
    color: #5d00ff;
}

.numberOTPWrapper {
    display: flex;
    /* align-items: center; */
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
}


/* OTP Send */

.otpWrapper {
    padding: 2px;
}

.otpMessage {
    color: #180043;
    margin-bottom: 16px;
}

.disabledInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.disabledInput {
    padding: 8px 16px;
    background-color: #f1f1f1;
    border-radius: 4px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.disabledInputField {
    border: none;
    background: transparent;
    outline: none;
    width: 100%;
    padding: 0;
    color: #5d00ff;
}

.disabledInputField[disabled] {
    cursor: not-allowed;
}

.editLink {
    margin-left: 12px;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
}

.editLink>h6 {
    margin-bottom: 0;
    color: #272727;
    font-size: 14px;
    font-weight: 800;
}

.verifyButton {
    background: #4a0db3;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 1.143em;
    font-weight: 600;
    height: 50px;
    width: 100%;
}

.verifyButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.resendWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
}

.resendLink {
    color: #5d00ff;
    text-decoration: none;
}

.resendSeparator {
    color: #149500;
    margin: 0 8px;
}


.otpContainer {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 20px 0;
}

.otpContainer input {
    background: #f8f8f8;
    border: 1px solid #bebfbd;
    border-radius: 8px;
    font-size: 20px;
    height: 50px;
    text-align: center;
    width: 50px;
}

.selectWrapper2 {
    position: relative;
    width: 120px;
}

.infoText {
    font-size: 14px;
    color: #333;
    text-align: center;
    margin-bottom: 8px;
}

.warningText {
    color: red;
    font-weight: bold;
}